import { Buymeacoffee } from "components/SupportButton";
import { EventType, PAGE_SIZE, Path } from "constants/constant";
import { Class } from "constants/enum";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { useHistory, useLocation } from "react-router-dom";
import { contentService } from "services/content.service";
import { Taxon } from "types/types";
import { Analytics } from "utils/analytics";
import "../App.scss";

type Props = {
  title: string;
  description?: string;
  classes: Class[];
  path: Path;
};

export const TaxonListPage = (props: Props) => {
  const [taxons, setTaxons] = useState<Taxon[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const location = useLocation();
  const limit = PAGE_SIZE;

  const page = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return parseInt(params.get("page")) || 1;
  }, [location.search]);

  const fetchTaxons = useCallback(async () => {
    setLoading(true);
    const [taxons, totalCount] = await contentService.listTaxons(
      (page - 1) * limit,
      limit,
      props.classes,
    );
    setTotalCount(totalCount);
    setTaxons(taxons);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    fetchTaxons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div id="wrapper">
      <Buymeacoffee />

      <Container style={{ padding: "2rem 1rem" }}>
        <h1>{props.title}</h1>
        {page == 1 && props.description && <p>{props.description}</p>}
        <Row style={{ padding: "1rem 0" }}>
          {!loading &&
            taxons.map((taxon) => (
              <Col style={{ padding: "1rem 0.5rem" }} md={4} key={taxon.nameEN}>
                <Image fluid src={taxon.images[0]} />
                <div
                  style={{
                    color: "#242424",
                    padding: "1rem 0.5rem 0rem",
                  }}
                >
                  <h6 style={{ margin: 0 }}>{taxon.nameEN}</h6>
                  <p style={{ margin: "0.25rem 0rem 0rem" }}>{taxon.nameTH}</p>
                  <small style={{ color: "#a7a7a7" }}>
                    <i>{taxon.nameSCI ?? "\u00A0"}</i>
                  </small>
                </div>
              </Col>
            ))}
        </Row>

        <div
          style={{
            justifyContent: "center",
            width: "100%",
            display: "flex",
          }}
        >
          <PaginationControl
            page={page}
            between={4}
            total={totalCount}
            limit={limit}
            changePage={(page) => {
              Analytics.track(EventType.PAGE_CHANGED, {
                value: page,
                page: location.pathname,
                search: location.search,
              });
              history.push(`${props.path}?page=${page}`);
            }}
            ellipsis={1}
          />
        </div>
      </Container>
    </div>
  );
};
