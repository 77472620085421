export const PAGE_SIZE = 24;

export enum Path {
  HOME = "/",
  GALLERY = "/gallery",
  BIRDS = "/birds",
  BUTTERFLY = "/butterfly",
  FAUNA = "/fauna",
  ABOUT = "/about",
  BLOG = "/blog",
}

export enum EventType {
  PAGE_VIEW = "PAGE_VIEW",
  LINK_CLICKED = "LINK_CLICKED",
  PAGE_CHANGED = "PAGE_CHANGED",
}
