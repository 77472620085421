type Experience = {
  title: string;
  subtitle: string;
  period: string;
  tasks: Array<string>;
};

type Item = {
  title: string;
  description: string;
};

type Contact = {
  phone: string;
  email: string;
};

type Typography = {
  experience: Experience[];
  extracurricularActivities: Experience[];
  skills: Item[];
  education: Item[];
  contact: Contact;
  name: Item;
  visitedCountries: string;
  bird: {
    description: string;
  };
  butterfly: {
    description: string;
  };
  fauna: {
    description: string;
  };
};

export const typography: Typography = {
  experience: [
    {
      title: "Specimen Management System",
      subtitle: "Virology Laboratory King Chulalongkorn Memorial Hospital",
      period: "AUG 2020-NOW",
      tasks: [
        "Technical leader on backend and frontend server using Nestjs",
        "Design overall system architecture",
        "Fullstack Developer Nestjs, React",
      ],
    },
    {
      title: "TCASter Mock Exam Full-stack Developer",
      subtitle: "Skooldio Internship",
      period: "JUN 2020 - JUL 2020",
      tasks: [
        "Developed frontend web application using React.js",
        "Developed backend web application using Loopback",
        "Worked on middleware using GraphQL",
      ],
    },
    {
      title: "Tutoring Platform System",
      subtitle:
        "University Project in System Analysis and Design and Software Engineering",
      period: "AUG 2019 - MAY 2020",
      tasks: [
        "Designed match maker system and using SDLC process, project methodology, requirement determination method",
        "Designed Class and MVC Diagram",
        "Prototyped responsive website application interface",
        "Developed full stacked web application with Laravel framework",
        "Worked with team with scrum framework",
      ],
    },
    {
      title: "Movie Ticket Reservation System",
      subtitle: "University project in Database Design subject",
      period: "NOV 2018 - DEC 2018",
      tasks: [
        "Designed a relational model database illustrated as ER Diagram",
        "Stored database using 'MySQL'",
        "Developed website UX/UI using React.js",
      ],
    },
    {
      title: "Java Game Developer",
      subtitle: "University project in Programming Methodology Subject",
      period: "NOV 2018 - DEC 2018",
      tasks: [
        "Designed and developped game applicationin Eclipse using JAVA",
        "Experienced using Object-Oriented Programming,Thread handling, and JavaFX",
      ],
    },
    {
      title: "Water Quality Detection with Mobile Application",
      subtitle: "Environment and Physics Science Project",
      period: "NOV 2018 - DEC 2018",
      tasks: [
        "Analyzed regression model using statistics tool software 'SPSS'",
        "Implemented mobile application software using Java",
        "Best of the Best Award” In The First ASEAN Science Project Competition",
        "'Bronze Medal' in I-SWEEEP 2015 at Texas, USA",
        "Finalist in Intel ISEF 2015 Pittsburg, USA",
      ],
    },
  ],
  extracurricularActivities: [
    {
      title: "President of Environmental Conservation Club",
      subtitle: "Chulalongkorn University",
      period: "MAR 2019 - MAY 2020",
      tasks: [
        "Administrated page on Facebook with 9,000 followers",
        "Organized environmental movement",
        "Organized “Sueb Nakhasathien” Remembrance",
        "Organized and participated in 6 environmental seminar events",
      ],
    },
    {
      title: "R&D Platform Startup",
      subtitle: "Young Technopreneur 7",
      period: "APR 2018 - OCT 2018",
      tasks: [
        "Participated in advanced business course and workshop",
        "Illustrated Business Model Canvas",
        "Pitched Business Idea to Bussiness experts",
        "20 Finalists",
      ],
    },
    {
      title: "President of Freshmen Year",
      subtitle: "Faculty of Engineering",
      period: "AUG 2017 - MAY 2018",
      tasks: [
        "Leaded conference with 200 participants for “Intania Fair”",
        "Coordinate with group leader for announcement",
      ],
    },
    {
      title: "Head of Souvenir Department",
      subtitle: "BCC 164 Anniversary Fair",
      period: "FEB 2016 - SEP 2016",
      tasks: [
        "Leaded team to design, produce and sale 25 souvenirs",
        "Generated up to 2 million-baht sales",
      ],
    },
  ],
  skills: [
    {
      title: "Frontend Frameworks",
      description: "Next.js, React.js, Vue.js, JS, TS, HTML, CSS",
    },
    {
      title: "Backend Frameworks",
      description:
        "Go, Nest.js, JS, TS, MongoDB, GraphQL, MySQL, Laravel, PHP, Java, GraphQL, Python, C++",
    },
    {
      title: "Others",
      description: "UX/UI Figma, Sketch, Ai, Docker",
    },
  ],
  education: [
    {
      title: "Computer Engineering, Chulalongkorn University",
      description: "GPAX 3.60",
    },
    {
      title: "Bangkok Christian College",
      description: "GPAX 3.95 / M.B.Palmer Award / POSN Computer#2",
    },
  ],
  contact: {
    phone: "085-330-1020",
    email: "kritkruaykitanon@gmail.com",
  },
  name: {
    title: "Krit Kruaykitanon (Ou)",
    description: "📸🗺️🏔️🦅🍜⌨️🪻🦋🦦🧑‍💻",
    // description: `I'm a lifelong learner with a special interest in software
    //   development, having worked in the sector for over two years and
    //   possessing over ten years of programming experience. I've
    //   collaborated on product teams in a variety of fields, including
    //   education, insurance, block-chain, e-commerce and entertainment.
    //   I'm eager to enhance my software engineering abilities in order
    //   to address more complex challenges.`,
  },
  visitedCountries: "🇹🇭🇸🇬🇦🇺🇺🇸🇱🇦🇰🇭🇲🇲🇻🇳🇮🇳🇵🇭🇲🇾🇮🇩🇫🇮🇨🇳🇭🇰🇲🇴🇯🇵🇰🇷🇦🇹🇩🇪🇳🇿",
  bird: {
    description:
      "ผมเริ่มดูนกตั้งแต่ปี 2020 และนี่คือรูปภาพนกที่ผมได้มีโอกาสถ่ายเอาไว้ ซึ่ง collection นี้จะเป็นนกเมืองไทยหรือนกที่เจอได้ในเมืองไทย ซึ่งคาดว่าน่าจะมีประมาณ 1,000 ชนิด",
  },
  butterfly: {
    description: "collection รวมผีเสื้อเมืองไทย",
  },
  fauna: {
    description: "ภาพถ่ายสัตว์ป่าจากทั่วทุกมุมโลก",
  },
};
