import "App.scss";
import { Footer } from "components/Footer";
import { Navbar } from "components/NavBar";
import { SEO } from "components/SEO";
import { EventType } from "constants/constant";
import { NavbarOption } from "constants/enum";
import { Routes } from "constants/routes";
import { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { RedirectPage } from "RedirectPage";
import { Analytics } from "utils/analytics";

const App = () => {
  const location = useLocation();

  useEffect(() => {
    Analytics.track(EventType.PAGE_VIEW, {
      page: location.pathname,
      search: location.search,
    });
  }, [location]);

  return (
    <div id="app-wrapper">
      <SEO />
      <Navbar option={NavbarOption.WHITE} />
      <Switch>
        {Routes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            render={(props) => <route.component {...route.props} {...props} />}
          />
        ))}
        <Route path={"/blogs/leh-2023"}>
          <RedirectPage to="https://medium.com/@kritkruaykitanon/ดูนกเอง-เลห์-ลาดัก-03f89175c3a9" />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
