import { TaxonListPage } from "views/TaxonListPage";
import { BlogDetailPage } from "views/BlogDetailPage";
import { GalleryPage } from "views/GalleryPage";
import { HomePage } from "views/HomePage";
import { Path } from "./constant";
import { Class } from "./enum";
import { typography } from "types/About.type";

type Route = {
  path: string;
  component: (...args) => JSX.Element;
  title: string;
  navbar?: boolean;
  props?: any;
};

export const Routes: Route[] = [
  {
    path: Path.HOME,
    component: HomePage,
    title: "Home",
  },
  // {
  //   path: Path.BLOG,
  //   component: BlogListPage,
  //   title: "Blog",
  //   navbar: true,
  // },
  {
    path: Path.GALLERY,
    component: GalleryPage,
    title: "Gallery",
    navbar: true,
  },
  {
    path: Path.BIRDS,
    component: TaxonListPage,
    title: "Birds",
    navbar: true,
    props: {
      title: "Birds",
      description: typography.bird.description,
      classes: [Class.BIRD],
      path: Path.BIRDS,
    },
  },
  {
    path: Path.BUTTERFLY,
    component: TaxonListPage,
    title: "Butterflies",
    navbar: true,
    props: {
      title: "Butterflies",
      description: typography.butterfly.description,
      classes: [Class.BUTTERFLY],
      path: Path.BUTTERFLY,
    },
  },
  {
    path: Path.FAUNA,
    component: TaxonListPage,
    title: "Fauna and Friends",
    navbar: true,
    props: {
      title: "Fauna and Friends",
      description: typography.fauna.description,
      classes: [Class.MAMMAL, Class.AMPHIBIAN, Class.REPTILE],
      path: Path.FAUNA,
    },
  },
  // {
  //   path: Path.ABOUT,
  //   component: AboutPage,
  //   title: "About",
  //   navbar: true,
  // },
  {
    path: Path.BLOG + "/:slug",
    component: BlogDetailPage,
    title: "Blog",
  },
];
